<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex lg12>
        <v-card>
          <hpo-filter
            :headers="headers"
            :viewName="viewName"
            v-on:applyFilter="applyFilter"
            v-on:quickFilter="quickFilter"
          ></hpo-filter>
          <v-data-table fixed-header height="750px"  
            :headers="headers"
            :items="items"
            :options.sync="pagination"
            :server-items-length="total_rows"
            :footer-props="items_per_page"
            :must-sort="true"
            :loading="loading"
            :mobile-breakpoint="100"
          >
            <template v-slot:item="{ item }">
              <tr :id="'r-' + item.ID">
                <td>
                  <v-edit-dialog :return-value.sync="item.ProductType" large lazy persistent @save="saveItem({'ID': item.ID, 'ProductType': item.ProductType})">
                    <div>{{ item.ProductType | display_value(product_type_list) }}</div>
                    <template v-slot:input>
                      <v-select
                        :items="product_type_list"
                        v-model="item.ProductType"
                        item-text="name"
                        item-value="value"
                        clearable
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </td>
                <td>
                  <v-edit-dialog :return-value.sync="item.ReceivePlaceID" large lazy persistent @save="saveItem({'ID': item.ID, 'ReceivePlaceID': item.ReceivePlaceID})">
                    <div>{{ item.ReceivePlaceID | display_value(stores, 'ID', 'Name') }}</div>
                    <template v-slot:input>
                      <v-select
                        :items="stores"
                        v-model="item.ReceivePlaceID"
                        item-text="Name"
                        item-value="ID"
                        clearable
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </td>
                <!-- <td class="text-end">{{ item.WeightFrom | currency('',1) }}</td>
                <td class="text-end">{{ item.WeightTo | currency('',1) }}</td> -->
                <td class="text-end">
                  <v-edit-dialog :return-value.sync="item.Amount" large lazy persistent @save="saveItem({'ID': item.ID, 'Amount': item.Amount})">
                    <div>{{ item.Amount | currency }}</div>
                    <template v-slot:input>
                      <h-currency-input v-model="item.Amount" :decimal="0" class="input-group--focused"></h-currency-input>
                    </template>
                  </v-edit-dialog>
                </td>
                <td class="text-end">
                  <v-edit-dialog :return-value.sync="item.AmountVolume" large lazy persistent @save="saveItem({'ID': item.ID, 'AmountVolume': item.AmountVolume})">
                    <div>{{ item.AmountVolume | currency }}</div>
                    <template v-slot:input>
                      <h-currency-input v-model="item.AmountVolume" :decimal="0" class="input-group--focused"></h-currency-input>
                    </template>
                  </v-edit-dialog>
                </td>
                <td class="text-end">
                  <v-edit-dialog :return-value.sync="item.Cost" large lazy persistent @save="saveItem({'ID': item.ID, 'Cost': item.Cost})">
                    <div>{{ item.Cost | currency }}</div>
                    <template v-slot:input>
                      <h-currency-input v-model="item.Cost" :decimal="0" class="input-group--focused"></h-currency-input>
                    </template>
                  </v-edit-dialog>
                </td>
                <td class="text-end">
                  <v-edit-dialog :return-value.sync="item.CostVolume" large lazy persistent @save="saveItem({'ID': item.ID, 'CostVolume': item.CostVolume})">
                    <div>{{ item.CostVolume | currency }}</div>
                    <template v-slot:input>
                      <h-currency-input v-model="item.CostVolume" :decimal="0" class="input-group--focused"></h-currency-input>
                    </template>
                  </v-edit-dialog>
                </td>
                <td>{{ (item.ModifiedDate || item.CreatedDate) | display_date }}</td>
                <td>{{ item.CreatedBy || item.ModifiedBy }}</td>
                <td class="text-center">
                  <v-icon medium class="mr-2" @click="gotoDetail(item.ID)"
                    >fa-edit</v-icon
                  >
                  <v-icon medium @click="deleteItem(item.ID)"
                    >fa-trash-alt</v-icon
                  >
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import configs from "@/configs";
import Filtering from "@/components/Filtering";
import _ from "lodash";

export default {
  components: {
    "hpo-filter": Filtering,
  },
  props: ["priceId"],
  data() {
    let dataType = configs.DATA_TYPE;
    return {
      headers: [
        {
          text: "Loại hàng",
          value: "ProductType",
          filterable: true,
          values: [],
          dataType: dataType["Enum"],
        },
        {
          text: "Kho VN",
          value: "ReceivePlaceID",
          filterable: true,
          values: [],
          dataType: dataType["Enum"],
        },
        // {
        //   text: "Cân nặng (Min)",
        //   value: "WeightFrom",
        //   align: "end",
        //   decimal: 1,
        //   filterable: true,
        //   dataType: dataType["Number"],
        // },
        // {
        //   text: "Cân nặng (Max)",
        //   value: "WeightTo",
        //   align: "end",
        //   decimal: 1,
        //   filterable: true,
        //   dataType: dataType["Number"],
        // },
        {
          text: "Cước cân",
          value: "Amount",
          align: "end",
          filterable: true,
          dataType: dataType["Number"],
        },
        {
          text: "Cước khối",
          value: "AmountVolume",
          align: "end",
          filterable: true,
          dataType: dataType["Number"],
        },
        {
          text: "Giá gốc cân",
          value: "Cost",
          align: "end",
          filterable: true,
          dataType: dataType["Number"],
        },
        {
          text: "Giá gốc khối",
          value: "CostVolume",
          align: "end",
          filterable: true,
          dataType: dataType["Number"],
        },
        {
          text: "Ngày sửa",
          value: "ModifiedDate",
          filterable: true,
          dataType: dataType["Date"],
        },
        {
          text: "Người sửa",
          value: "ModifiedBy",
          filterable: true,
          dataType: dataType["String"],
        },
        {
          text: "Thao tác",
          value: "",
          align: "center",
          sortable: false,
          filterable: false,
        },
      ],
      pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
      items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
      viewName: "feeWeight_list",
      filterConditions: [],
      quickFilterConditions: [],
      requireRules: [(v) => !!v || "Trường dữ liệu không được để trống."]
    };
  },
  watch: {
    pagination: {
      handler: function (val, oldVal) {
        let filterConditions = this.$store.state.commons.filterConditions;
        this.filterConditions = !_.isEmpty(filterConditions)
          ? filterConditions[this.viewName]
          : [];
        this.filter_data();
      },
      deep: true,
    },
    stores() {
        let header_item = _.find(this.headers, {value: "ReceivePlaceID"});
        if(header_item) {
            header_item.values = _.map(this.stores, (item) => {
                return {
                    value: item.ID,
                    name: item.Name
                }
            }) || []
        }
    },
    product_type_list() {
      let header_item = _.find(this.headers, { value: "ProductType" });
      if (header_item) {
        header_item.values = this.product_type_list;
      }
    }
  },
  computed: {
    ...mapState({
      items: (state) => state.feeWeight.all.data,
      total_rows: (state) => state.feeWeight.all.total,
      loading: (state) => state.feeWeight.loading,
      currentFeeWeight: (state) => state.feeWeight.selected,
      configuration: state => state.configuration.selected,
      stores: (state) => state.commons.stores
    }),
    product_type_list() {
      return this.configuration.ProductTypes ? JSON.parse(this.configuration.ProductTypes) : [];
    },
  },
  methods: {
    filter_data: function () {
      var param = {
        pagination: this.pagination,
        filter: this.filterConditions,
        quickFilter: this.quickFilterConditions,
      };
      if (this.priceId) {
        param.filter.push({
          column: "PriceID",
          value: this.priceId,
          condition: "equal",
        });
      }
      this.$store.dispatch("feeWeight/getList", param);
    },
    gotoDetail: function (id) {
      let routeData = this.$router.resolve({
        name: "FeeWeightDetail",
        params: {
          Pid: id,
          title: `Chi tiết phí cân nặng (#${id})`,
        },
      });
      window.open(routeData.href, "_blank");
    },
    deleteItem: function (id) {
      window.getApp.$emit("REQUEST_DELETE_DATA", {'id': id, 'viewName': this.viewName});
    },
    saveItem(item) {
      this.$store.dispatch("feeWeight/setDetail", {
        data: item,
        id: item.ID
      });
    },
    applyFilter(filterConditions) {
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      this.pagination = configs.PAGINATION;
      this.pagination.sortBy = sortBy;
      this.pagination.sortDesc = sortDesc;
      this.filterConditions = filterConditions;
      this.filter_data();
    },
    quickFilter(filterConditions) {
      this.quickFilterConditions = filterConditions;
      this.filter_data();
    },
    createData(username = "") {
      this.$router.push({
        name: "FeeWeightDetail",
        params: {
          Pid: "tao-moi",
          title: `Tạo mới phí cân nặng`,
        },
      });
    },
    storeDisplay(value) {
      const store = _.find(this.stores, { ID: value });
      return store ? store.Name : "";
    }
  },
  mounted() {
    this.$store.dispatch("commons/getAllStores");
    this.$store.dispatch("configuration/getDetail", 1);
    this.$root.$emit("REQUEST_ROOT_OPTION", {});
    this.$root.$on("REQUEST_CREATE_DATA", this.createData);
    this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
    this.$root.$on("CONFIRMED_REMOVE_DATA", item => {
      if(item.viewName == this.viewName) {
        this.$store.dispatch("feeWeight/removeDetail", item.id);
      }
    });
  },
  beforeDestroy() {
    this.$root.$off("REQUEST_CREATE_DATA", this.createData);
    this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
    this.$root.$off("CONFIRMED_REMOVE_DATA");
  },
};
</script>
